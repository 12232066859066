/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import { Checkbox } from '@material-ui/core';
import FormControlLabel from '@material-ui/core/FormControlLabel/FormControlLabel';
import { LocationsContextElement } from 'contexts/LocationsContext/LocationsContext';
import { LocationsContextType } from 'contexts/LocationsContext/LocationsContextType';
import { Address } from 'models/Address';
import React, { useContext } from 'react';
import { FieldErrors, UseFormRegister, UseFormSetValue } from 'react-hook-form';
import './styles.scss';

interface Params {
	show: boolean;
	setValue: UseFormSetValue<Address>;
	register: UseFormRegister<Address>;
	errors: any;
}

const CreateAddress: React.FC<Params> = ({ show, setValue, register, errors }) => {
	const { onZipCodeChanges } = useContext<LocationsContextType>(LocationsContextElement);

	let timer;
	const handleZipCodeChanges = async (value: string) => {
		clearTimeout(timer);
		timer = setTimeout(() => {
			onZipCodeChanges(value.trim(), onReceiveExternalLocation);
			clearTimeout(timer);
		}, 600);
	};

	const onReceiveExternalLocation = (location?: Address) => {
		if (location) {
			setValue('city', location.city);
			setValue('stateSigla', location.stateSigla);
			setValue('address', location.address);
			setValue('neighborhood', location.neighborhood);
		}
	};

	const formItemClass = (fieldName: string) => {
		const formError = errors[fieldName] as FieldErrors;
		const errorClass = formError?.type ? 'has-error' : '';

		return `form-item-content ${fieldName} ${errorClass}`;
	};

	const mainContent = () => {
		return (
			<div className="create-address-content">
				<div className={formItemClass('zipCode')}>
					<label>CEP</label>
					<input
						{...register('zipCode', { required: true, maxLength: 24 })}
						onChange={e => {
							handleZipCodeChanges(e.target.value);
						}}
						placeholder="00000-000"
						maxLength={9}
					/>
				</div>
				<div className={formItemClass('city')}>
					<label>Cidade</label>
					<input maxLength={127} {...register('city', { required: true, maxLength: 127 })} placeholder="Informe a cidade" />
				</div>
				<div className={formItemClass('stateSigla')}>
					<label>UF</label>
					<input maxLength={2} {...register('stateSigla', { required: true, maxLength: 2 })} placeholder="UF" />
				</div>
				<div className={formItemClass('neighborhood')}>
					<label>Bairro</label>
					<input maxLength={30} {...register('neighborhood', { required: true, maxLength: 30 })} placeholder="Digite o bairro" />
				</div>
				<div className={formItemClass('address')}>
					<label>Endereço</label>
					<input maxLength={48} {...register('address', { required: true, maxLength: 48 })} placeholder="Informe o endereço" />
				</div>
				<div className={formItemClass('complement')}>
					<label>Complemento</label>
					<input maxLength={100} {...register('complement', { required: false, maxLength: 100 })} placeholder="Informe o complemento" />
				</div>
				<div className={formItemClass('addressNumber')}>
					<label>N°</label>
					<input maxLength={10} {...register('addressNumber', { required: true, maxLength: 10 })} placeholder="00" />
				</div>

				<FormControlLabel
					label="Desejo salvar este endereço como Endereço Padrão"
					{...register('addressDefault')}
					control={<Checkbox className="address-checkbox" name="addressDefault" />}
				/>
			</div>
		);
	};

	return show ? mainContent() : <></>;
};

export default CreateAddress;
