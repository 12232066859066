/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import LockIcon from '@material-ui/icons/Lock';
import EditAccountResponse from 'components/Account/EditAccount/EditAccountResponse';
import FormValidator from 'components/Shared/FormFields/FormValidator';
import Modal from 'components/Shared/Modal';
import RenewPassForm from 'components/Shared/RenewPass';
import { AccountContextElement } from 'contexts/AccountContext/AccountContext';
import { AccountContextType } from 'contexts/AccountContext/AccountContextType';
import { RequisitionError } from 'models/RequisitionError';
import { UserInput } from 'models/User';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useDispatch } from 'react-redux';
import { toast } from 'react-toastify';
import history from 'routes/history';
import { store } from 'store';
import { signOutRequest } from 'store/modules/auth/actions';
import { RouteList } from 'utils/constants';
import { inputPhoneMask } from 'utils/pipe';
import './styles.scss';

const MobileEditAccount: React.FC = () => {
	const { onUpdateUser } = useContext<AccountContextType>(AccountContextElement);
	const user = store.getState().data.user;
	const dispatch = useDispatch();
	const [isEditing, setIsEditing] = useState<boolean>(false);
	const [isLoading, setIsLoading] = useState<boolean>(false);
	const [isResetCompleted, setIsResetCompleted] = useState<boolean>(false);
	const [isUpdateCompleted, setIsUpdateCompleted] = useState<boolean>(false);
	const [showRenewPassContent, setShowRenewPassContent] = useState<boolean>(false);
	const [hasErrorOnEmail, setHasErrorOnEmail] = useState<boolean>(false);
	const [responseType, setResponseType] = useState<'data' | 'pass'>('data');

	const [value, setValue] = useState(user.phone);

	const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
		setValue(inputPhoneMask(e.target.value));
	};

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<UserInput>({
		defaultValues: {
			name: user.name,
			phone: user.phone,
			email: user.email,
			email_gestor: user.email_gestor,
		},
	});

	const validateFields = (data: UserInput) => {
		let emailCcField = '';
		if (data.email_gestor) emailCcField = data.email_gestor;
		const emailsDot = emailCcField.match(new RegExp(';', 'g')) || [];
		const emailsCompleted = emailCcField.match(new RegExp('@', 'g')) || [];

		const diff = emailsCompleted.length - emailsDot.length;

		if (diff === 0 || diff === 1) {
			return true;
		} else {
			setHasErrorOnEmail(true);
			return false;
		}
	};

	const auth = store.getState().auth;

	const onCloseResetMessage = () => {
		setIsResetCompleted(false);
		dispatch(signOutRequest(auth.id));
		history.replace(RouteList.LOGIN);
	};

	const onUpdateUserComplete = (hasError: RequisitionError) => {
		setIsLoading(false);
		if (hasError) {
			switch (hasError.code) {
				case 404:
					toast.error('Usuário não encontrado!');
					break;
				default:
					toast.error('Erro ao atualizar informações do usuário!');
					break;
			}
		} else {
			toast.success('Dados alterados com sucesso!');
			setIsUpdateCompleted(true);
		}
	};

	const onSubmit = (data: UserInput) => {
		if (isEditing) {
			if (validateFields(data)) {
				setIsLoading(true);
				setIsUpdateCompleted(false);
				setResponseType('data');
				data.id = user.id;
				data.email = user.email;
				onUpdateUser(data, onUpdateUserComplete);
				setIsEditing(false);
			}
		}
	};

	const responseContent = () => {
		return responseType === 'data' ? (
			<EditAccountResponse type={responseType} show={isLoading && !isUpdateCompleted} isLoading={isLoading} />
		) : (
			<EditAccountResponse type={responseType} show={isLoading || isResetCompleted} isLoading={isLoading} onClose={onCloseResetMessage} />
		);
	};

	const actionsButtonsContent = () => {
		return isEditing ? (
			<button type="submit" value="submit">
				Salvar
			</button>
		) : (
			<div
				className="edit-button"
				onClick={() => {
					setIsEditing(true);
				}}
			>
				<span>Alterar</span>
			</div>
		);
	};

	const emailCopyValidator = () => {
		return hasErrorOnEmail ? (
			<div className="form-item-content-validator">
				<span>{`Os e-mails devem ser reparados por ponto e vírgula (;)`}</span>
			</div>
		) : (
			<></>
		);
	};

	const renewPassContent = () => {
		const title = 'Alterar Senha';
		return (
			<Modal show={showRenewPassContent} title={title} onClose={() => setShowRenewPassContent(false)}>
				<RenewPassForm email={user.email} />
			</Modal>
		);
	};

	const formContent = () => {
		return (
			<form id="myForm" onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div className="main-header">
					<span>Informações da conta</span>
					{actionsButtonsContent()}
				</div>
				<div className="form-row-content">
					<div className="form-item-content user-name">
						<label>Nome</label>
						<input
							defaultValue={user.name}
							{...register('name', { required: true, maxLength: 50 })}
							placeholder="Informe seu nome"
							maxLength={50}
							disabled={!isEditing}
						/>
						<FormValidator {...{ formName: 'name', validatorName: 'Nome', errors, isDirty }} />
					</div>
					<div className="form-item-content user-phone">
						<label>Telefone:</label>
						<input
							value={value}
							{...register('phone', { required: true, maxLength: 15 })}
							placeholder="(35) 99999-9999"
							maxLength={15}
							onChange={onChange}
							defaultValue={user.phone}
							disabled={!isEditing}
						/>
						<FormValidator {...{ formName: 'phone', validatorName: 'Telefone', errors, isDirty }} />
					</div>
				</div>
				<div className="form-row-content">
					<div className="form-item-content user-email">
						<label>E-mail</label>
						<input placeholder="Informe seu e-mail" maxLength={100} defaultValue={user.email} disabled={!isEditing} />
						<FormValidator {...{ formName: 'email', validatorName: 'E-mail', errors, isDirty }} />
					</div>
					<div className="form-item-content user-email">
						<label>{`Com cópia (cc)`}</label>
						<input
							{...register('email_gestor', { maxLength: 250 })}
							placeholder="Os e-mails devem ser reparados por ponto e vírgula (;)"
							maxLength={250}
							onBlur={() => setHasErrorOnEmail(false)}
							defaultValue={user.email_gestor}
							disabled={!isEditing}
						/>
						{emailCopyValidator()}
					</div>
				</div>
				<div className="edit-account-options-password">
					<LockIcon />
					<span onClick={() => setShowRenewPassContent(true)}>Alterar Senha</span>
				</div>
			</form>
		);
	};

	return (
		<div className="mobile-edit-account-content">
			{formContent()}
			{renewPassContent()}
			{responseContent()}
		</div>
	);
};

export default MobileEditAccount;
