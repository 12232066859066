/**
 * Developed by: Inatel Competence Center
 * Copyright 2019, Prática
 * Author: Lucas Marciano
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import axios, { AxiosRequestConfig } from 'axios';
import { AccountInput } from 'models/Account';
import ClientInfo from 'models/ClientInfo';
import { FilterData } from 'models/FilterData';
import LoginInfo from 'models/LoginInfo';
import { RequestServiceOrderCancel } from 'models/RequestServiceOrderCancel';
import { ResponseServer } from 'models/ResponseServer';
import { ServiceOrderFeedbackAnswers } from 'models/ServiceOrderFeedback';
import { ServiceOrderRequest } from 'models/ServiceOrderRequest';
import { TransferInput } from 'models/Transfer';
import UserAppConfigs from 'models/UserAppConfigs';

/**
 * Axios apiPrp.
 */
export const apiPrp: AxiosRequestConfig = {
	baseURL: process.env.REACT_APP_API_URL,
	responseType: 'json',
	headers: { accept: 'application/json', 'content-type': 'application/json' },
	timeout: 40000,
	withCredentials: true,
	maxRedirects: 5,
	validateStatus: (status: number) => status >= 200 && status < 300,
	transformResponse: response => {
		if (response.error) {
			console.log(response.error);
		}
		return response;
	},
};

// appUsersReports //
export const createReport = async (data: any) => {
	apiPrp.url = `appUsersReports/createReport`;
	apiPrp.data = data;
	apiPrp.method = 'post';

	return axios.request(apiPrp);
};

// appOsFeedbacks //
export const getAppOsFeedbackByServiceOrderId = async (id: number) => {
	apiPrp.url = `appOsFeedbacks/getAppOsFeedbackByServiceOrderId?id=${id}`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const createNewServiceOrderFeedback = async (serviceOrderFeedback: ServiceOrderFeedbackAnswers) => {
	apiPrp.url = `appOsFeedbacks/register`;
	apiPrp.data = serviceOrderFeedback;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

// equipmentTransfers //
export const requestEquipmentTransfer = async (file: Record<string, any>) => {
	apiPrp.url = `equipmentTransfers/transfer`;
	apiPrp.data = file;
	apiPrp.method = 'post';
	return axios.request<Record<string, any>>(apiPrp);
};

// equipments //
export const getEquipments = async (clientId: number, limit: number, offset: number, search = '', lendingId?: number, equipsFilter?: FilterData) => {
	let url = `equipments/getEquipments?clientId=${clientId}&search=${encodeURIComponent(search)}&limit=${limit}&offset=${offset}`;
	if (lendingId) {
		url += `&lendingId=${lendingId}`;
	}
	if (equipsFilter) {
		url += `&equipsFilter=${JSON.stringify(equipsFilter)}`;
	}
	apiPrp.url = url;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const getEquipmentById = async (equipId: number) => {
	apiPrp.url = `equipments/getEquipmentById?equipId=${equipId}`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const getEquipmentBySerialNumber = async (serialNumber: string) => {
	apiPrp.url = `equipments/getEquipmentBySerialNumber?serialNumber=${serialNumber}`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

// notificationsApp //
export const getAllNotifications = async (userId: number, limit: number, offset: number, search?: string, notifyFilter?: FilterData) => {
	let url = `notificationsApp/getAllNotifications?userId=${userId}&limit=${limit}&offset=${offset}`;
	if (search) {
		url += `&search=${encodeURIComponent(search)}`;
	}
	if (notifyFilter) {
		url += `&notifyFilter=${JSON.stringify(notifyFilter)}`;
	}
	apiPrp.url = url;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const updateNotificationStatus = async (id: number, isReading?: boolean) => {
	let query = `id=${id}`;
	if (isReading) {
		query += `&isReading=${isReading}`;
	}
	apiPrp.url = `notificationsApp/updateStatus?${query}`;
	apiPrp.method = 'put';
	return axios.request(apiPrp);
};

export const updateAllNotificationStatus = async (userId: number, reverse?: boolean) => {
	let url = `notificationsApp/updateAllStatus?userId=${userId}`;
	if (reverse) {
		url += `&reverse=${reverse}`;
	}
	apiPrp.url = url;
	apiPrp.method = 'put';
	return axios.request(apiPrp);
};

export const removeNotification = async (id: number) => {
	apiPrp.url = `notificationsApp/${id}`;
	apiPrp.method = 'delete';
	return axios.request(apiPrp);
};

export const removeAllNotifications = async (userId: number) => {
	apiPrp.url = `notificationsApp/removeAllNotifications?userId=${userId}`;
	apiPrp.method = 'delete';
	return axios.request(apiPrp);
};

// reportedDefects //
export const getClientDefects = async (clientId: number, search = '') => {
	apiPrp.url = `reportedDefects/getClientDefects?clientId=${clientId}&search=${encodeURIComponent(search)}`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

// serviceOrders //
export const createNewServiceOrder = async (newServiceOrder: ServiceOrderRequest) => {
	apiPrp.url = `serviceOrders/register`;
	apiPrp.data = newServiceOrder;
	apiPrp.method = 'post';
	return axios.request<ResponseServer>(apiPrp);
};

export const getServiceOrders = async (clientId: number, limit: number, offset: number, search = '', lendingId?: number, ordersFilter?: FilterData) => {
	let url = `serviceOrders/getServiceOrders?clientId=${clientId}&limit=${limit}&offset=${offset}&search=${encodeURIComponent(search)}`;
	if (lendingId) {
		url += `&lendingId=${lendingId}`;
	}

	if (ordersFilter) {
		url += `&ordersFilter=${JSON.stringify(ordersFilter)}`;
	}

	apiPrp.url = url;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const getServiceOrdersByEquip = async (equipId: number, limit: number, offset: number, ordersFilter?: FilterData) => {
	let url = `serviceOrders/getServiceOrdersByEquip?equipId=${equipId}&limit=${limit}&offset=${offset}`;

	if (ordersFilter) {
		url += `&ordersFilter=${JSON.stringify(ordersFilter)}`;
	}

	apiPrp.url = url;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const getServicesOrdersByCode = async (code: string) => {
	apiPrp.url = `serviceOrders/getServicesOrdersByCode?code=${code}`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

// tech_assistance_files //
export const uploadFiles = async (data: any) => {
	apiPrp.url = '/upload';
	apiPrp.data = data;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const deleteFileOnS3 = async (fileId: string) => {
	apiPrp.url = `/upload/${fileId}`;
	apiPrp.method = 'delete';
	return axios.request(apiPrp);
};

export const getBase64 = async (id_img: number) => {
	apiPrp.url = `tech_assistance_files?filter=%7B%22where%22%3A%20%7B%22id%22%3A%20%22${id_img}%22%7D%7D`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

// technicalAssistances //
export const registerFollowUp = async (techAssist: any) => {
	apiPrp.url = `technicalAssistances/registerFollowUp`;
	apiPrp.data = techAssist;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const registerCancelRequest = async (requestServiceOrderCancel: RequestServiceOrderCancel) => {
	apiPrp.url = `technicalAssistances/registerCancelRequest`;
	apiPrp.data = { requestServiceOrderCancel };
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

// transfers //
export const registerTransferByAccount = async (newAccount: AccountInput) => {
	apiPrp.url = `transfers/register`;
	apiPrp.method = 'post';
	apiPrp.data = { ...newAccount, createAccount: true };
	return axios.request(apiPrp);
};

export const registerTransfer = async (transfer: TransferInput) => {
	apiPrp.url = `transfers/register`;
	apiPrp.method = 'post';
	apiPrp.data = transfer;
	return axios.request(apiPrp);
};

export const verifyEquipment = async (transfer: TransferInput) => {
	apiPrp.url = `transfers/verifyEquipment`;
	apiPrp.method = 'post';
	apiPrp.data = transfer;
	return axios.request(apiPrp);
};

// users //
export const updateUser = async (user: any) => {
	apiPrp.url = `users/updateUser`;
	apiPrp.data = user;
	apiPrp.method = 'put';
	return axios.request(apiPrp);
};

export const updatePass = async (data: any) => {
	apiPrp.url = `users/updatePass`;
	apiPrp.data = data;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const resetPass = async (data: any) => {
	apiPrp.url = `users/resetPass`;
	apiPrp.data = data;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const createUser = async (user: AccountInput) => {
	apiPrp.url = `users/createUser`;
	apiPrp.data = user;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const userFirstAccess = async (user: AccountInput) => {
	apiPrp.url = `users/userFirstAccess`;
	apiPrp.data = user;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const updateUserToken = async (deviceToken: any, userId: number) => {
	apiPrp.url = `users/deviceToken`;
	apiPrp.method = 'post';
	apiPrp.data = { deviceToken, userId };
	return axios.request<LoginInfo>(apiPrp);
};

export const singIn = async (email: string, password: string, deviceToken?: any) => {
	apiPrp.url = `users/singin`;
	apiPrp.method = 'post';
	apiPrp.data = { email, password, deviceToken };
	return axios.request(apiPrp);
};

export const userInfo = async (userId: number, storeId?: number) => {
	apiPrp.url = `users/userInfo?userId=${userId}`;

	if (storeId) {
		apiPrp.url += `&storeId=${storeId}`;
	}

	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

// usersAppConfigs //
export const updateSettings = async (newAppSettings: UserAppConfigs) => {
	apiPrp.url = `usersAppConfigs/updateSettings`;
	apiPrp.data = newAppSettings;
	apiPrp.method = 'put';
	return axios.request(apiPrp);
};

// usersClients //
export const getAllClients = async (userId: number, search = '') => {
	apiPrp.url = `usersClients/getClients?userId=${userId}&search=${encodeURIComponent(search)}`;
	apiPrp.method = 'get';
	return axios.request<ClientInfo>(apiPrp);
};

export const getUserStores = async (client_id: any, limit: number, offset: number, serchTerm = '', getAll?: string, userId?: number) => {
	apiPrp.url = `usersClients/getStores?id=${client_id}&limit=${limit}&offset=${offset}&serchTerm=${encodeURIComponent(serchTerm)}&getAll=${getAll}&userId=${userId}`;
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const createUserClient = async (data: any) => {
	apiPrp.url = `usersClients`;
	apiPrp.data = data;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

export const removeUserClient = async (userId: number, clientId: number) => {
	apiPrp.url = `usersClients/removeUserClient?userId=${userId}&clientId=${clientId}`;
	apiPrp.method = 'delete';
	return axios.request(apiPrp);
};

// AppVersions //
export const getLastVersion = async (userId: number) => {
	apiPrp.url = `appsVersions/getLastVersion?app=APP_CLIENT`;
	if (userId) {
		apiPrp.url += `&userId=${userId}`;
	}
	apiPrp.method = 'get';
	return axios.request(apiPrp);
};

export const reportUserVersion = async (userId: number) => {
	apiPrp.url = `appsVersions/reportUserVersion?userId=${userId}`;
	apiPrp.method = 'post';
	return axios.request(apiPrp);
};

// https://viacep.com.br/ws/ //
export const getLocationByZipCode = async (zipCode: string) => {
	return axios.get('https://viacep.com.br/ws/' + zipCode + '/json/');
};
