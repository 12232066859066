/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FormValidator from 'components/Shared/FormFields/FormValidator';
import LoadingProgress from 'components/Shared/LoadingProgress';
import PasswordField from 'components/Shared/PasswordField';
import { AuthContextElement } from 'contexts/AuthContext/AuthContext';
import { AuthContextType } from 'contexts/AuthContext/AuthContextType';
import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext } from 'react';
import { useForm } from 'react-hook-form';
import { NavLink } from 'react-router-dom';
import { toast } from 'react-toastify';
import history from 'routes/history';
import { RouteList } from 'utils/constants';
import './styles.scss';

const LoginForm: React.FC = () => {
	const { isLoading, onSignIn } = useContext<AuthContextType>(AuthContextElement);

	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<LoginInput>();

	const onSubmit = (data: LoginInput) => {
		onSignIn(data, onComplete);
	};

	const onComplete = (hasError: RequisitionError) => {
		if (hasError) {
			switch (hasError.code) {
				case 401:
					toast.error('Verifique seu login e senha!');
					break;
				case 404:
					toast.error('Usuário não encontrado!');
					break;
				case 409:
					toast.error('Usuário com cadastro incompleto!');
					break;
				default:
					toast.error('Erro ao realizar login!');
					break;
			}
		}
	};

	const onCreateAccountClick = () => {
		history.push(RouteList.NEW_ACCOUNT);
	};

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div>
					<div className="form-item-content">
						<label>Login</label>
						<input {...register('email', { required: true, minLength: 8, maxLength: 100 })} placeholder="Informe seu e-mail" maxLength={100} />
						<FormValidator {...{ formName: 'email', validatorName: 'Login', errors, isDirty }} />
					</div>
					<div className="form-item-content">
						<label>Senha</label>
						<PasswordField register={register('password', { required: true, maxLength: 20 })} placeholder="Informe sua senha" />
						<FormValidator {...{ formName: 'password', validatorName: 'Senha', errors, isDirty }} />
					</div>
				</div>
				<div className="login-form-options">
					<NavLink to={RouteList.RESET_PASS}>Esqueci minha senha</NavLink>
				</div>
				<div className="login-form-actions">
					<button className="login-submit" type="submit">
						ENTRAR
					</button>
					<button className="login-create-account" type="button" onClick={onCreateAccountClick}>
						CRIAR CONTA
					</button>
				</div>
			</form>
		);
	};

	return (
		<div className="login-form">
			{formContent()}
			<LoadingProgress show={isLoading} text="Realizando login..." />
		</div>
	);
};

export default LoginForm;
