/**
 * Developed by: Inatel Competence Center
 * Copyright 2021, Prática
 * Author: Digital Endeavors
 * All rights are reserved. Reproduction in whole or part is
 * prohibited without the written consent of the copyright owner.
 */

import FormValidator from 'components/Shared/FormFields/FormValidator';
import LoadingProgress from 'components/Shared/LoadingProgress';
import { AccountContextElement } from 'contexts/AccountContext/AccountContext';
import { AccountContextType } from 'contexts/AccountContext/AccountContextType';
import { LoginInput } from 'models/LoginInfo';
import { RequisitionError } from 'models/RequisitionError';
import React, { useContext, useState } from 'react';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import history from 'routes/history';
import ResetPassSuccess from '../ResetPassSuccess';

import './styles.scss';

const ResetPassForm: React.FC = () => {
	const { isLoading, onResetPass } = useContext<AccountContextType>(AccountContextElement);
	const {
		register,
		handleSubmit,
		formState: { errors, isDirty },
	} = useForm<LoginInput>();
	const [email, setEmail] = useState<string>('');
	const [isCompleted, setIsCompleted] = useState<boolean>(false);

	const onSubmit = (data: LoginInput) => {
		setEmail(data.email);
		setIsCompleted(false);
		onResetPass(data.email, onComplete);
	};

	const onComplete = (hasError: RequisitionError) => {
		if (hasError) {
			switch (hasError.code) {
				case 404:
					toast.error('Usuário não encontrado!');
					break;
				default:
					toast.error('Erro ao solicitar restauração de senha!');
					break;
			}
		} else {
			setIsCompleted(true);
		}
	};

	const sendAgain = () => {
		if (email) {
			onResetPass(email, onComplete);
		} else {
			setIsCompleted(false);
		}
	};

	const formContent = () => {
		return (
			<form onSubmit={handleSubmit(onSubmit)} className="main-form-content">
				<div>
					<div className="form-item-content">
						<label>Confirme seu e-mail</label>
						<input {...register('email', { required: true, minLength: 8, maxLength: 100 })} placeholder="Informe seu e-mail" maxLength={100} />
						<FormValidator {...{ formName: 'email', validatorName: 'e-mail', errors, isDirty }} />
					</div>
				</div>
				<div className="reset-pass-form-actions">
					<button className="reset-pass-submit" type="submit">
						CONFIRMAR
					</button>
					<button
						className="reset-pass-cancel"
						type="button"
						onClick={() => {
							history.goBack();
						}}
					>
						CANCELAR
					</button>
				</div>
			</form>
		);
	};

	return (
		<div className="reset-pass-form">
			{formContent()}
			<LoadingProgress show={isLoading} text="Enviando e-mail de recuperação..." />
			<ResetPassSuccess show={!isLoading && isCompleted} sendAgain={sendAgain} />
		</div>
	);
};

export default ResetPassForm;
